<template>
    <div class="password-recovery-form box">

        <form @submit.prevent="sendPasswordRecoveryLink" v-if="!resetPasswordLinkHasBeenSent && !readyToResetPassword" :key="'password_reset_link_request_form'">

            <h4 class="title is-4">{{$t('Reset password')}}</h4>

            <b-field :label="$t('Your email')"
                     :type="{'is-danger': $_p_formHasError('email')}"
                     :message="$_p_formGetError('email')">
                <b-input type="email"
                         name="email"
                         @input="$_p_formClearError('email')"
                         data-cy="email"
                         v-model="emailForPasswordRecovery"
                         icon="envelope"
                         :placeholder="$_p_getRandomEmailPlaceholder()"> </b-input>
            </b-field>

            <b-field>
                <b-button type="is-primary"
                          data-cy="send-password-recovery-link"
                          :loading="formBusy"
                          @click="sendPasswordRecoveryLink">{{$t('Reset')}}</b-button>
            </b-field>
        </form>



        <div v-else-if="resetPasswordLinkHasBeenSent && !readyToResetPassword" key="check_your_mail_message">
            <h3 class="title is-3" >{{$t('Reset link has been send')}}</h3>
            <p data-cy="success-send-recovery-link">{{$t('We sent a password reset link to the specified mail. Check your mail and follow the instructions')}}</p>
        </div>



        <div action="" v-if="readyToResetPassword && !passwordResetSuccess" key="new_password_form">

            <h4 class="title is-4">{{$t('Set new password')}}</h4>

            <b-field :label="$t('Your new password')">
                <b-input type="password"
                         data-cy="password"
                         v-model="resetPasswordData.password"
                         icon=""
                         placeholder="********">
                </b-input>
            </b-field>

            <b-field :label="$t('Confirm password')"
                     :type="{'is-danger': $_p_formHasError('password') || $_p_formHasError('email')}"
                     :message="$_p_formGetError('password') || $_p_formGetError('email')">
                <b-input type="password"
                         data-cy="password_confirmation"
                         v-model="resetPasswordData.password_confirmation"
                         icon=""
                         @keyup.native.enter="resetPassword"
                         placeholder="********">
                </b-input>
            </b-field>

            <b-field>
                <b-button type="is-primary"
                          data-cy="send-new-password"
                          :loading="formBusy"
                          @click="resetPassword">{{$t('Save password')}}</b-button>
            </b-field>

        </div>

        <b-message v-if="passwordResetSuccess" :title="$t('Password successful changed')" type="is-success" :aria-close-label="$t('Close')">
            <b-button tag="router-link" to="/" type="is-success">{{$t('continue') | capitalize}}</b-button>
        </b-message>

    </div>
</template>

<script>
    import getRandomEmailPlaceholderMixin from "@/mixins/getRandomEmailPlaceholderMixin";
    import BButton from "buefy/src/components/button/Button";
    import {AUTH, RESET_PASSWORD, SEND_RESET_PASSWORD_LINK} from "@/store/modules/auth/action-types";
    import {mapActions} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    export default {
        components: {BButton},
        data: () => ({
            emailForPasswordRecovery: '',
            resetPasswordLinkHasBeenSent: false,
            busy: false,
            resetPasswordData: {
                password: '',
                password_confirmation: ''
            },
            passwordResetSuccess: false,
        }),
        methods:{
            ...mapActions('auth', [
                SEND_RESET_PASSWORD_LINK,
                AUTH,
                RESET_PASSWORD,
            ]),
            sendPasswordRecoveryLink(){
                this.$_p_formDispatch(SEND_RESET_PASSWORD_LINK, this.emailForPasswordRecovery)
                    .then(() => {
                        this.$gEvent('password_reset_link_sent');
                        this.resetPasswordLinkHasBeenSent = true;
                    }).catch(() => {
                        this.$gEvent('password_reset_link_send_fail');
                    });
            },
            resetPassword(){

                this.$_p_formDispatch(RESET_PASSWORD, {
                    email: this.$route.query.email,
                    token: this.$route.query.token,
                    password: this.resetPasswordData.password,
                    password_confirmation: this.resetPasswordData.password_confirmation,
                }).then(() => {
                    this.$gEvent('password_reset_success');

                    this.$buefy.toast.open({
                        type: 'is-success',
                        message: this.$t('Password updated'),
                    });

                    this.$router.push('/authentication');

                })
                .catch(() => {
                    this.$gEvent('password_reset_fail');

                    this.$buefy.toast.open({
                        type: 'is-danger',
                        message: this.$t("Can't reset password"),
                    });
                });
            },
        },
        mixins: [getRandomEmailPlaceholderMixin, formHelperMixin],
        name: "PasswordResetForm",
        computed: {
            readyToResetPassword(){
                return this.$route.query.token && this.$route.query.email;
            }
        }
    };
</script>

<style scoped>

</style>