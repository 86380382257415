<template>
    <div>
        <div class="hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-half-desktop">
                                <password-reset-form />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PasswordResetForm from "@/components/Auth/PasswordResetForm";
    export default {
        name: "PasswordReset",
        title(){ return this.$t('Reset password');},
        components: {PasswordResetForm},
        created() {
            this.$Progress.finish();
        }
    };
</script>

<style scoped>

</style>